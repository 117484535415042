import React from 'react'
import PropTypes from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

interface IncomeDetailsControlsDispatchProps {
  setDeleteInProgress: (payload: boolean) => void
  setFormSubmittingInProgress: (payload: boolean) => void
  setUnsavedChangesBlocked: (payload: boolean) => void
}

interface IncomeDetailsControlsStateProps {
  isActionInProgress: boolean
  isDeleteInProgress: boolean
  isUnsavedChangesBlocked: boolean
}

const IncomeDetailsControlsDispatchContext = React.createContext<IncomeDetailsControlsDispatchProps | undefined>(
  undefined
)
const IncomeDetailsControlsStateContext = React.createContext<IncomeDetailsControlsStateProps | undefined>(undefined)

enum IncomeDetailsActions {
  DELETE = 'delete',
  FORM_SUBMIT = 'submit',
}

interface IncomeDetailsControlsProviderProps {
  children: React.ReactNode
}

export function IncomeDetailsControlsProvider({ children }: IncomeDetailsControlsProviderProps) {
  const [actionInProgress, setActionInProgress] = React.useState<Nullable<IncomeDetailsActions>>(null)
  const [isUnsavedChangesBlocked, setUnsavedChangesBlocked] = React.useState(false)

  const stateValue = React.useMemo(
    () => ({
      isActionInProgress: Boolean(actionInProgress),
      isDeleteInProgress: actionInProgress === IncomeDetailsActions.DELETE,
      isUnsavedChangesBlocked,
    }),
    [actionInProgress, isUnsavedChangesBlocked]
  )

  const dispatchValue = React.useMemo(
    () => ({
      setDeleteInProgress: (payload: boolean) => setActionInProgress(payload ? IncomeDetailsActions.DELETE : null),
      setFormSubmittingInProgress: (payload: boolean) =>
        setActionInProgress(payload ? IncomeDetailsActions.FORM_SUBMIT : null),
      setUnsavedChangesBlocked,
    }),
    []
  )

  return (
    <IncomeDetailsControlsDispatchContext.Provider value={dispatchValue}>
      <IncomeDetailsControlsStateContext.Provider value={stateValue}>
        {children}
      </IncomeDetailsControlsStateContext.Provider>
    </IncomeDetailsControlsDispatchContext.Provider>
  )
}

IncomeDetailsControlsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useIncomeDetailsControlsState() {
  return useAssertContext(IncomeDetailsControlsStateContext)
}

export function useIncomeDetailsControlsDispatch() {
  return useAssertContext(IncomeDetailsControlsDispatchContext)
}
