import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { connect } from 'react-redux'

import { isFieldHighlighted } from '@helpers'

import { ExpenseDetailsFormValues } from '@components/forms/ExpenseDetailsForm/types'

import { InvoiceType } from '@constants'

import { CompanyVatCategorySelect, SelectOptionData } from './CompanyVatCategorySelect'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

const INVOICE_CLASS_FILTER_MAP = {
  [InvoiceType.EXPENSE]: 0,
  [InvoiceType.INCOME]: 1,
}

type NameFieldKey = `assignments.${number}.company_vat_category_name`

interface CompanyVatCategoryFieldProps {
  assignmentPrefix: `assignments.${number}.`
  className?: string
  companyVatCategoryOptions?: CompanyVatCategory[]
  companyVatOptions: CompanyVatType[]
  disabled?: boolean
  highlighted?: boolean
  invoiceType: InvoiceType
  isLabelHighlighted?: boolean
  label?: React.ReactNode
}

function PureCompanyVatCategoryField({
  assignmentPrefix,
  className,
  companyVatCategoryOptions = [],
  companyVatOptions,
  disabled,
  highlighted = false,
  invoiceType,
  isLabelHighlighted = false,
  label,
}: CompanyVatCategoryFieldProps) {
  const classes = useStyles()

  const vat = useWatch({ name: `${assignmentPrefix}vat` })
  const vatArea = useWatch({ name: 'vat_area' })
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ExpenseDetailsFormValues>()

  const fieldName: NameFieldKey = `${assignmentPrefix}company_vat_category_name`

  const {
    field: nameField,
    fieldState: { error: nameError },
  } = useController<ExpenseDetailsFormValues, NameFieldKey>({
    control,
    name: fieldName,
  })
  const {
    field: codeField,
    fieldState: { error: codeError },
  } = useController<ExpenseDetailsFormValues, `assignments.${number}.company_vat_category_code`>({
    control,
    name: `${assignmentPrefix}company_vat_category_code`,
  })
  const {
    field: idField,
    fieldState: { error: idError },
  } = useController<ExpenseDetailsFormValues, `assignments.${number}.company_vat_category_id`>({
    control,
    name: `${assignmentPrefix}company_vat_category_id`,
  })

  const filteredOptions = React.useMemo<CompanyVatCategory[]>(() => {
    const percentFilter = companyVatOptions.find(({ id }) => id === vat)?.percent
    const invoiceClassFilter = INVOICE_CLASS_FILTER_MAP[invoiceType]

    return companyVatCategoryOptions.filter(
      ({ percent, invoice_class, vat_area }) =>
        percent === percentFilter && invoice_class === invoiceClassFilter && vat_area === vatArea
    )
  }, [companyVatCategoryOptions, companyVatOptions, invoiceType, vat, vatArea])

  const handleChange = React.useCallback(
    (valueOption: Nullable<SelectOptionData>) => {
      codeField.onChange(valueOption?.code ?? null)
      idField.onChange(valueOption?.id ?? null)
      nameField.onChange(valueOption?.name ?? null)
    },
    [codeField, idField, nameField]
  )

  const error = nameError || codeError || idError
  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting
  const value = nameField.value ?? null

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(className, classes.selectRoot, 'form-control', {
        'form-control-error': hasError,
      })}
      error={hasError}
      disabled={isFieldDisabled}
    >
      {label && (
        <InputLabel
          htmlFor={fieldName}
          shrink
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
        >
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <CompanyVatCategorySelect
          className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
          disabled={disabled}
          name={fieldName}
          onChange={handleChange}
          options={filteredOptions}
          value={value}
        />
      </div>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}

export const CompanyVatCategoryField = connect((state: Store) => ({
  companyVatOptions: state.auth.companyVats.data,
  companyVatCategoryOptions: state.auth.company.data.vat_categories,
}))(PureCompanyVatCategoryField)

CompanyVatCategoryField.displayName = 'CompanyVatCategoryField'
