import React from 'react'

import { useAssertContext } from '@hooks/index'

import { CompanyIntegrationProviders } from '@constants/index'

import { CompanyIntegrationsContext } from './CompanyIntegrationsProvider'
import {
  AcountoIntegrationProvider,
  BillingoIntegrationProvider,
  PayeeIntegrationProvider,
  SzamlazzIntegrationProvider,
  VasfalaIntegrationProvider,
} from './types'

//* hooks

export function useCompanyIntegration() {
  return useAssertContext(CompanyIntegrationsContext)
}

export function useAcountoCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegrations } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.acounto as const
    const details = companyIntegrations.find(obj => obj.integration === integration) as
      | AcountoIntegrationProvider
      | undefined

    return {
      details,
      hasIntegration: Boolean(details?.is_connected),
      integration,
      updateCompanyIntegrations,
    }
  }, [companyIntegrations, updateCompanyIntegrations])
}

export function useBillingoCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegrations } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.billingo as const
    const details = companyIntegrations.find(obj => obj.integration === integration) as
      | BillingoIntegrationProvider
      | undefined

    return {
      details,
      hasIntegration: Boolean(details?.is_connected && !details?.data?.suspended_at),
      integration,
      updateCompanyIntegrations,
    }
  }, [companyIntegrations, updateCompanyIntegrations])
}

export function useVasfalaCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegrations } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.vasfala as const
    const details = companyIntegrations.find(obj => obj.integration === integration) as
      | VasfalaIntegrationProvider
      | undefined

    return {
      details,
      hasIntegration: Boolean(details?.is_connected),
      integration,
      updateCompanyIntegrations,
    }
  }, [companyIntegrations, updateCompanyIntegrations])
}

export function usePayeeCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegrations } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.payee as const
    const details = companyIntegrations.find(obj => obj.integration === integration) as
      | PayeeIntegrationProvider
      | undefined

    return {
      details,
      hasIntegration: Boolean(details?.is_connected),
      integration,
      updateCompanyIntegrations,
    }
  }, [companyIntegrations, updateCompanyIntegrations])
}

export function useSzamlazzCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegrations } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.szamlazz as const
    const details = companyIntegrations.find(obj => obj.integration === integration) as
      | SzamlazzIntegrationProvider
      | undefined

    return {
      details,
      hasIntegration: Boolean(details?.is_connected),
      integration,
      updateCompanyIntegrations,
    }
  }, [companyIntegrations, updateCompanyIntegrations])
}
