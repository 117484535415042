export const EMPTY_EXPENSE_ASSIGNMENT: ExpenseDetailsAssignment = {
  expense_type: null,
  net_amount: '',
  gross_amount: '',
  vat_amount: '',
  vat: null,
  tags: [],
  ledger_number_code: null,
  ledger_number_id: null,
  ledger_number_name: null,
  vat_ledger_number_code: null,
  vat_ledger_number_id: null,
  vat_ledger_number_name: null,
  job_number: '',
  note: '',
  company_vat_category_code: null,
  company_vat_category_id: null,
  company_vat_category_name: null,
}

export const EMPTY_INCOME_VALUES: IncomeDetailsFormInitialValues = {
  accounting_period_end: null,
  accounting_period_start: null,
  assignments: [],
  currency: 1, // will update on initialization - currently HUF is the default currency and classifier return HUF as default also so exchange_rate must be 1 as default
  due_at: null,
  exchange_date: null, // will update on initialization
  exchange_rate: null, // will update on initialization
  fulfilled_at: null,
  gross_amount: null,
  income_type: 0, // will update on initialization
  invoice_number: null,
  issued_at: null,
  need_accounting_period: undefined, //! FE only - keep it undefined and RFF won't submit it to backend
  net_amount: null, // will update on initialization when needed
  paid_through: null,
  partner_account_number: null,
  partner_address: null,
  partner_city: null,
  partner_country: null,
  partner_id: null, //! FE only - keep it undefined and RFF won't submit it to backend
  partner_name: null,
  partner_tax_number: null,
  partner_zip_code: null,
  payment_method: null,
  planned_payment_date: null,
  reschedule_payment: undefined, //! FE only - keep it undefined and RFF won't submit it to backend
  secondary_id: null,
  simple_tags: [],
  tags: [],
  user_updated: null,
  vat_amount: null, // will update on initialization when needed
  vat_area: null,
}

export const EMPTY_INCOME_ASSIGNMENT: IncomeDetailsAssignment = {
  revenue_type: null,
  net_amount: '',
  gross_amount: '',
  vat_amount: '',
  vat: null,
  tags: [],
}

// Field's subscription
export const FIELD_SUBSCRIPTION = {
  dirtySinceLastSubmit: true,
  error: true,
  modifiedSinceLastSubmit: true,
  submitError: true,
  touched: true,
  value: true,
}

export const FORM_SUBSCRIPTION = {
  dirty: true,
  dirtySinceLastSubmit: true,
  initialValues: true,
  pristine: true,
  submitError: true,
  submitErrors: true,
  submitting: true,
  // values: true,
}

export const DEFAULT_BLUR_FIELD_LISTENER_SUBSCRIPTION = { value: true, active: true }

export const DEFAULT_CHANGE_FIELD_LISTENER_SUBSCRIPTION = { ...DEFAULT_BLUR_FIELD_LISTENER_SUBSCRIPTION, initial: true }

export const USE_FIELD_VALUE_CONFIG = { subscription: { value: true } }
