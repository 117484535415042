import React from 'react'

import { useWatch } from 'react-hook-form'

import { InvoiceType } from '@constants'

import { CategoryTypeOption } from '../InvoiceAssignments/types'
import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'

interface AssignmentLedgerNumberFieldProps
  extends Pick<LedgerNumberFieldProps, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'label'> {
  assignmentPrefix: `assignments.${number}.`
  categoryTypeOptions: CategoryTypeOption[] // TODO later it can be RevenueType too
  invoiceType: InvoiceType
}

export function AssignmentLedgerNumberField({
  assignmentPrefix,
  categoryTypeOptions,
  invoiceType,
  ...rest
}: AssignmentLedgerNumberFieldProps) {
  const categoryTypeId = useWatch({ name: `${assignmentPrefix}${invoiceType}_type` })
  const recommendations = categoryTypeOptions.find(({ id }) => id === categoryTypeId)?.ledger_numbers ?? []

  return (
    <LedgerNumberField {...rest} fieldPrefix={`${assignmentPrefix}ledger_number`} recommendations={recommendations} />
  )
}
