import React from 'react'

import { useLocation } from 'react-router'

/**
 * Scroll to element with the given id when the component is loaded and url's hash matches the id
 * @param id - id of the element to scroll to
 * @param loaded - control when to scroll (Optional, Default: scroll on mount)
 * @returns - ref and id, reference object and id of the HTMLDivElement
 */
export function useScrollToHash(id: string, loaded = true) {
  const { hash } = useLocation()
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (loaded && hash.substring(1, hash.length) === id) {
      const timer = window.setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      })

      return () => {
        if (timer) {
          window.clearTimeout(timer)
        }
      }
    }
  }, [hash, id, loaded])

  return { ref, id }
}
