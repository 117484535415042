import React from 'react'

export type PreventSubmitSuccessCallback = {
  (payload?: boolean): void
}

export function usePreventSubmit() {
  const allowSubmitSuccessRef = React.useRef(true)

  /**
   * Callback function to control allowSubmitSuccessRef
   *
   * @param {boolean | undefined} flag - set allowSubmitSuccessRef value (optional)
   */
  const preventSubmitSuccess = React.useCallback<PreventSubmitSuccessCallback>((flag = false) => {
    // work with reference only in handleSubmit (state value not updated before the submit cycle run)
    allowSubmitSuccessRef.current = flag
  }, [])

  return { allowSubmitSuccessRef, preventSubmitSuccess }
}
