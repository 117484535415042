import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import * as yup from 'yup'

import { createAriaAttributes } from '@helpers'

import { EditorMode } from '@hooks/useEditor'

import { ReactHookForm } from '@components/ui'
import { CustomDialog, CustomDialogHeader } from '@components/ui/Dialogs'

import { DIALOG_CLOSING_TIMEOUT } from '@constants/dialog'

import { DialogResponse } from './DialogResponse'
import { LoadingDialogOverlay } from './LoadingDialogOverlay'

import { FormDialogBody } from './styles'

enum DialogState {
  CLOSED = 'closed', // initial state when the dialog is not open
  LOADING = 'loading', // during initial onLoad (only in edit mode)
  RESPONSE = 'response', // enter when initial onLoad failed OR form submitSucceed
  FORM = 'form', // user can work with the form
}

interface FormDialogState<FormValues extends Record<string, unknown>, ApiData> {
  initialError: null | string
  initialValues: Partial<FormValues>
  dialogState: DialogState
  storedValues: null | ApiData
}

const INITIAL_STATE = {
  initialError: null,
  dialogState: DialogState.CLOSED,
  storedValues: null,
}

interface FormDialogChildrenData<ApiData> {
  mode: EditorMode
  storedValues: ApiData | null
}

interface FormDialogProps<FormValues extends Record<string, unknown>, ApiData> {
  children: React.ReactNode | ((formDialogData: FormDialogChildrenData<ApiData>) => JSX.Element)
  createInitialValues: (data?: Partial<ApiData>) => FormValues
  dialogTitle: React.ReactChild
  initialData?: Partial<ApiData>
  mode: EditorMode
  onClose: VoidFunction
  onLoad: AsyncFunction<void, ApiData>
  onLoadFailureText: React.ReactChild
  onSubmit: AsyncFunction<FormValues, ApiData>
  onSubmitFailure?: (payload: unknown) => void
  onSubmitSuccess?: (results: ApiData) => void
  onSubmitSuccessText: React.ReactChild | ((storedValues: ApiData | null, mode: EditorMode) => React.ReactChild)
  open: boolean
  skipSuccessResponse?: boolean
  testId?: string
  getValidationSchema?: (payload: Nullable<ApiData>) => yup.SchemaOf<FormValues>
}

/**
 * When a form needs to be displayed in a dialog, use this component which handles loading, success, and error states as well.
 *
 * @template FormValues - generic type for the actual form's values - needs to be a `type`
 * @template ApiData - generic type for the data returned by the API
 * @param {FormDialogProps<FormValues, ApiData>} props - The properties for the FormDialog component
 * @param {Function|React.ReactNode} props.children - The actual form used with render props
 * @param {Function} props.createInitialValues - Function that creates the initial values for the form
 * @param {React.ReactChild} props.dialogTitle - Title of the dialog
 * @param {EditorMode} props.mode - Can be `EDIT` or `CREATE`
 * @param {VoidFunction} props.onClose - Function that closes the dialog
 * @param {AsyncFunction<void, ApiData>} props.onLoad - Needs to return a Promise and passes data to `createInitialValues`
 * @param {React.ReactChild} props.onLoadFailureText - Text to display when the initial load fails
 * @param {AsyncFunction<FormValues, ApiData>} props.onSubmit - Needs to return a Promise and passes data to `renderOnSubmitSuccess`
 * @param {Function} [props.onSubmitFailure] - (optional) Function that gets called if submit fails with the error response
 * @param {Function} [props.onSubmitSuccess] - (optional) Function that gets called if submit succeeds with the data returned by the API
 * @param {Function|React.ReactChild} props.onSubmitSuccessText - Either a function that gets called with the data returned by the API and the mode, or a string to display on success
 * @param {boolean} props.open - If the dialog should be open or not
 * @param {boolean} [props.skipSuccessResponse] - (optional) If the success response should be skipped
 * @param {string} [props.testId] - (optional) Test ID for the form
 * @param {Function} [props.getValidationSchema] - (optional) Function that returns the validation schema for the form
 * @returns {JSX.Element} The FormDialog component
 */
export function FormDialog<FormValues extends Record<string, unknown>, ApiData>({
  children,
  createInitialValues,
  dialogTitle,
  getValidationSchema,
  initialData,
  mode,
  onClose,
  onLoad,
  onLoadFailureText,
  onSubmit,
  onSubmitFailure,
  onSubmitSuccess,
  onSubmitSuccessText: onSubmitSuccessTextProp,
  open,
  skipSuccessResponse = false,
  testId,
}: FormDialogProps<FormValues, ApiData>) {
  const [{ storedValues, initialError, initialValues, dialogState }, setState] = React.useState<
    FormDialogState<FormValues, ApiData>
  >({ ...INITIAL_STATE, initialValues: createInitialValues(initialData) })

  // when open dialog call onLoad
  React.useEffect(() => {
    // need to only run when DialogState is CLOSED to ensure that prop changes does not trigger onLoad again
    if (open && dialogState === DialogState.CLOSED) {
      if (mode === EditorMode.EDIT) {
        setState(state => ({
          ...state,
          dialogState: DialogState.LOADING,
        }))
        onLoad()
          .then(results => {
            setState(state => ({
              ...state,
              dialogState: DialogState.FORM,
              initialValues: createInitialValues(results),
              storedValues: results,
            }))
          })
          .catch(errorMsg => {
            setState(state => ({
              ...state,
              initialError: errorMsg,
              dialogState: DialogState.RESPONSE,
            }))
          })
      } else {
        // EditorMode.CREATE
        setState(state => ({
          ...state,
          dialogState: DialogState.FORM,
          initialValues: createInitialValues(initialData),
        }))
      }
    }
  }, [onLoad, mode, open, createInitialValues, dialogState, initialData])

  // reset state when dialog is closed after a delay to allow transition animation
  React.useEffect(() => {
    let timeout: number
    if (dialogState !== DialogState.CLOSED && !open) {
      timeout = window.setTimeout(() => {
        // check once more if we're still in response state to avoid clearing a valid new form
        setState(state =>
          state.dialogState !== DialogState.CLOSED
            ? {
                ...INITIAL_STATE, // we need to create initial values here as well otherwise validator functions could break which listens to all of the form's values
                initialValues: createInitialValues(),
              }
            : state
        )
      }, DIALOG_CLOSING_TIMEOUT)
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [createInitialValues, dialogState, open])

  const validationSchema = React.useMemo(() => getValidationSchema?.(storedValues), [getValidationSchema, storedValues])

  const handleFormSubmitSuccess = React.useCallback(
    (results: ApiData) => {
      onSubmitSuccess?.(results)
      if (skipSuccessResponse) {
        onClose()
      } else {
        setState(state => ({
          ...state,
          storedValues: results,
          dialogState: DialogState.RESPONSE,
        }))
      }
    },
    [onClose, onSubmitSuccess, skipSuccessResponse]
  )

  function handleOnSubmitSuccessText() {
    return typeof onSubmitSuccessTextProp === 'function'
      ? onSubmitSuccessTextProp(storedValues, mode)
      : onSubmitSuccessTextProp
  }

  // store the text into a variable to have `onErrorText` and `onSubmitSuccessText` the same type for `DialogResponse`
  const onSubmitSuccessText = handleOnSubmitSuccessText()

  // ensure to have the same aria attributes and pass down to dialog body
  const ariaIdPrefix = __uniqueId()
  const aria = createAriaAttributes(ariaIdPrefix)

  const canCloseDialogWithoutButton = dialogState === DialogState.RESPONSE

  return (
    <CustomDialog
      ariaIdPrefix={ariaIdPrefix}
      open={open}
      onClose={onClose}
      shouldCloseOnEsc={canCloseDialogWithoutButton}
      shouldCloseOnOverlayClick={canCloseDialogWithoutButton}
    >
      <CustomDialogHeader title={dialogTitle} borderless={dialogState === DialogState.RESPONSE} />
      <FormDialogBody aria-busy={dialogState === DialogState.LOADING}>
        <LoadingDialogOverlay loading={dialogState === DialogState.LOADING} />
        <ReactHookForm
          data-testid={testId}
          onSubmit={onSubmit}
          onSubmitSuccess={handleFormSubmitSuccess}
          onSubmitFail={onSubmitFailure}
          id={aria.describedby}
          initialValues={initialValues}
          skipResetAfterSuccessfulSubmit
          validationSchema={validationSchema}
          values={initialValues}
        >
          <DialogResponse
            aria={aria}
            error={initialError}
            onClose={onClose}
            onErrorText={onLoadFailureText}
            onSubmitSuccessText={onSubmitSuccessText}
          >
            {typeof children === 'function' ? children({ storedValues, mode }) : children}
          </DialogResponse>
        </ReactHookForm>
      </FormDialogBody>
    </CustomDialog>
  )
}
