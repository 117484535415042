import { IntegrationAction, IntegrationProvider, IntegrationsBlockState } from './types'

export const INITIAL_STATE: IntegrationsBlockState = {
  companyIntegrations: [],
  error: null,
  fetched: false,
  loading: false,
}

type IntegrationsBlockAction =
  | { type: IntegrationAction.INIT }
  | { type: IntegrationAction.SUCCESS; payload: IntegrationsBlockState['companyIntegrations'] }
  | { type: IntegrationAction.FAILURE; payload: string }
  | { type: IntegrationAction.UPDATE; payload: IntegrationProvider[] }

export function reducer(state: IntegrationsBlockState, action: IntegrationsBlockAction) {
  switch (action.type) {
    case IntegrationAction.INIT: {
      return {
        ...state,
        loading: true,
      }
    }
    case IntegrationAction.SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        companyIntegrations: action.payload,
      }
    case IntegrationAction.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case IntegrationAction.UPDATE:
      return {
        ...state,
        companyIntegrations: action.payload,
      }
    default:
      return state
  }
}
