import styled from 'styled-components'

import { ReactHookFormCheckboxField } from '@components/ui/FormElements'

export const TagIconContainerDiv = styled.div`
  display: flex;
  & > svg {
    margin-left: 3px;
  }
`

export const ButtonsContainerDiv = styled.div<{ $withoutMargin?: boolean }>`
  margin-top: ${({ $withoutMargin = false }) => ($withoutMargin ? 0 : '40px')};
  display: flex;
  justify-content: space-between;
`

export const StyledReactHookFormCheckboxField = styled(ReactHookFormCheckboxField)`
  height: 56px;
  justify-content: flex-end;
`
