import __find from 'lodash/find'

import { CompanyIntegrationProviders, INVOICE_SOURCE_VALUES } from '@constants'

//* COMPANY INTEGRATION HELPERS *//
function getCompanyIntegrations(state: Store) {
  return state.auth.company.data?.integrations || []
}

function findCompanyIntegration(state: Store, integration: CompanyIntegrationProviders) {
  const integrations = getCompanyIntegrations(state)

  return __find(integrations, obj => obj.integration === integration)
}

/**
 * Generic helper to check if company has the given integration type
 * @param state - redux store
 * @param integrationType - integration type
 * @returns - true if company has the given integration type
 */
export function companyHasIntegrationType(state: Store, integrationType: CompanyIntegrationProviders) {
  return Boolean(findCompanyIntegration(state, integrationType))
}

export function getSzamlazzHuBackUrl(state: Store) {
  const szamlazzHuIntegration = findCompanyIntegration(state, CompanyIntegrationProviders.szamlazz)
  return szamlazzHuIntegration?.back_url
}

export function companyHasIntegration(state: Store) {
  const integrations = getCompanyIntegrations(state)
  return integrations.length > 0
}

export function getLiquidityIntegrationsInfo(state: Store) {
  const info = {} as { [key in 10 | 20]: { isActive: boolean; hasIntegration: boolean } }
  // szamlazz
  info[CompanyIntegrationProviders.szamlazz] = {
    isActive: state.liquidity.szamlazz_incomes,
    hasIntegration: companyHasIntegrationType(state, CompanyIntegrationProviders.szamlazz),
  }

  // billingo
  info[CompanyIntegrationProviders.billingo] = {
    isActive: state.liquidity.billingo_incomes,
    hasIntegration: companyHasIntegrationType(state, CompanyIntegrationProviders.billingo),
  }

  return info
}

//* INVOICE INTEGRATION HELPERS *//
// Invoice
// income page uses this because no nav there
export function isIntegrationInvoice(data: ExpenseDetailsFrontendValues | IncomeDetailsFrontendValues) {
  return data.integration_type != null // not null or not undefined
}

// V1 helper
export function isNAVInvoice(data: ExpenseDetailsFrontendValues | IncomeDetailsFrontendValues) {
  // vasfala is act as a NAV invoice
  if (data.integration_type === CompanyIntegrationProviders.vasfala) {
    return true
  }

  return data.source === INVOICE_SOURCE_VALUES.SZAMLA_SOURCE_NAV // expense, quarantine, incomes list and details
}
// expense and quarantine pages use this because nav there
// only show integration when quarantine_status is null
export function isIntegrationInvoiceWithoutNav(data: ExpenseDetailsFrontendValues | IncomeDetailsFrontendValues) {
  return isIntegrationInvoice(data) && !isNAVInvoice(data)
}

//* PARTNER INTEGRATION HELPERS *//
// note: partners module is not typed yet
export const isBillingoPartner = (data: any) =>
  (data?.integration_type || []).includes(CompanyIntegrationProviders.billingo)
export const isSzlahuPartner = (data: any) =>
  (data?.integration_type || []).includes(CompanyIntegrationProviders.szamlazz)
export const hasIntegration = (data: any) => (data?.integration_type || []).length > 0
