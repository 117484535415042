import React from 'react'

import { InvoiceType } from '@constants'

import { VAT_LEDGER_NUMBER_SUGGESTION } from './constants'
import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'

interface VatLedgerNumberFieldProps
  extends Pick<LedgerNumberFieldProps, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'label'> {
  assignmentPrefix: `assignments.${number}.`
  invoiceType?: InvoiceType
}

export function VatLedgerNumberField({
  assignmentPrefix,
  invoiceType = InvoiceType.EXPENSE,
  ...rest
}: VatLedgerNumberFieldProps) {
  return (
    <LedgerNumberField
      {...rest}
      fieldPrefix={`${assignmentPrefix}vat_ledger_number`}
      suggestion={VAT_LEDGER_NUMBER_SUGGESTION[invoiceType]}
    />
  )
}
