import React from 'react'

import { FormattedMessage } from 'react-intl'

import { PureOriginIcon } from '@components/ui'
import { Typography } from '@components/ui/Typography'

import { INCOME_TYPE_NYUGTA_OSSZESITO, INVOICE_ORIGINS } from '@constants'

import { getSourceName } from './helper'

import { HeadingTypography, ReasonBlockWrapperDiv } from './styles'

interface IntegrationBlockProps {
  invoiceType?: number
  origin: 'billingo' | 'szamlazz'
  readOnly: boolean
  variant: 'expense' | 'revenue'
}

export function IntegrationBlock({ invoiceType, origin, readOnly, variant }: IntegrationBlockProps) {
  const isBillingoInvoice = origin === INVOICE_ORIGINS.BILLINGO
  const isSzlahuInvoice = origin === INVOICE_ORIGINS.SZAMLAZZ
  const source = getSourceName(origin)
  return (
    <ReasonBlockWrapperDiv>
      <HeadingTypography>
        <PureOriginIcon origin={origin} />
        {isSzlahuInvoice && invoiceType !== INCOME_TYPE_NYUGTA_OSSZESITO && (
          <FormattedMessage id="invoice.disabledEdit.szamlazzhu.heading" defaultMessage="Számlázz.hu-s számla" />
        )}
        {isSzlahuInvoice && invoiceType === INCOME_TYPE_NYUGTA_OSSZESITO && (
          <FormattedMessage
            id="invoice.disabledEdit.szamlazzhu.heading.receipt"
            defaultMessage="Számlázz.hu-s nyugta összesítő"
          />
        )}
        {isBillingoInvoice && (
          <FormattedMessage id="invoice.disabledEdit.billingo.heading" defaultMessage="Billingo-s számla" />
        )}
      </HeadingTypography>
      {!readOnly && (
        <Typography align="center">
          {variant === 'expense' && (
            <FormattedMessage
              id="invoice.disabledEdit.expense.reasonText"
              defaultMessage="A számla adatait a {source} fiókodból importáltuk, ezért csak a címkéket, a pénztárcát és a költségtípusokat tudod szerkeszteni."
              values={{
                source,
              }}
            />
          )}
          {variant === 'revenue' && invoiceType !== INCOME_TYPE_NYUGTA_OSSZESITO && (
            <FormattedMessage
              id="invoice.disabledEdit.revenue.reasonText"
              defaultMessage="A számla adatait a {source} fiókodból importáltuk, ezért csak a címkéket, a pénztárcát és a bevételtípusokat tudod szerkeszteni."
              values={{
                source,
              }}
            />
          )}
          {variant === 'revenue' && invoiceType === INCOME_TYPE_NYUGTA_OSSZESITO && (
            <FormattedMessage
              id="invoice.disabledEdit.revenue.reasonText.receipt"
              defaultMessage="A nyugta összesítő adatait a {source} fiókodból importáltuk, ezért csak a címkéket tudod szerkeszteni."
              values={{
                source,
              }}
            />
          )}
        </Typography>
      )}
    </ReasonBlockWrapperDiv>
  )
}
