import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getIsAutokasszaEnabled } from '@helpers'

import {
  Accordion,
  AutoKasszaIcon,
  ExternalLink,
  InfoText,
  ReactHookFormAmountField,
  ReactHookFormDateField,
  Typography,
} from '@components/ui'
import { DoubleColumn, FirstColumn, FormGrid2Column, SecondColumn } from '@components/ui/Wrappers' // separate imports to avoid circular dependency issues

import { LINKS, PaidThroughType } from '@constants'

import { PaidThroughFormValues } from '../types'
import { IsAutokasszaCheckbox } from './IsAutokasszaCheckbox'
import { LastSavedBalancesList } from './LastSavedBalancesList'

import { MoreInformationMessage } from '@messages'
import messages from '../messages'
import { CollapseGrid2Column, FormSection } from '../styles'

// need min-height, otherwise the dialog would jump around in sizes
const DoubleColumnWithMinHeight = styled(DoubleColumn)`
  min-height: 64px;
`

const BlockTitleSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`

const AutokasszaInfoContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`

// this exists because the Autokassza component on the dialog is bigger then the normal collapse component - the collapse cuts of what doesn't fit
const CollapseGrid2ColumnWithMaxHeight = styled(CollapseGrid2Column)`
  max-height: ${props => (props.open ? '250px' : '0')};
`

const StyledExternalLink = styled(ExternalLink)`
  padding-left: 34px;
  font-size: 12px;
`

const FormGrid2ColumnWithTopPadding = styled(FormGrid2Column)`
  padding-top: 12px;
`

interface EditorFormBalanceSectionProps {
  bankProviders: BankProvider[]
  detailsData?: Nullable<BackendPaidThroughDetailResponse>
  isEdit: boolean
  provider: Nullable<number> | undefined
}

function PureEditorFormBalanceSection({ bankProviders, detailsData, isEdit, provider }: EditorFormBalanceSectionProps) {
  const { formatMessage } = useIntl()
  const {
    formState: { isSubmitted, isSubmitSuccessful, errors, defaultValues },
  } = useFormContext<PaidThroughFormValues>()

  const [balanceAccordionOpen, setBalanceAccordionOpen] = React.useState(false)

  const [paidThroughTypeValue, isAutoKasszaValue] = useWatch<
    PaidThroughFormValues,
    ['paidthrough_type', 'is_autokassza']
  >({
    name: ['paidthrough_type', 'is_autokassza'],
  })

  const isBalanceRequired = isAutoKasszaValue && (!isEdit || detailsData?.balances?.length === 0)

  if (!balanceAccordionOpen && isSubmitted && !isSubmitSuccessful && (errors?.balance || errors?.value_date)) {
    // when user try to submit form with errors in balance section prevent to close accordion
    setBalanceAccordionOpen(true)
  }

  React.useEffect(() => {
    if (!defaultValues?.is_autokassza && isAutoKasszaValue) {
      setBalanceAccordionOpen(true)
    }
  }, [defaultValues?.is_autokassza, isAutoKasszaValue, setBalanceAccordionOpen])

  return (
    <FormSection as={FormGrid2Column}>
      <DoubleColumn>
        <Accordion
          variant="plain"
          iconSize="small"
          headerContent={
            <Typography size="700-md" tag="h4" data-testid="balance-accordion-label">
              {formatMessage(messages.balanceAccordionHeader)}
            </Typography>
          }
          open={balanceAccordionOpen}
          openHandler={() => setBalanceAccordionOpen(!balanceAccordionOpen)}
        >
          <FormGrid2ColumnWithTopPadding>
            <FirstColumn>
              <ReactHookFormAmountField
                required={isBalanceRequired}
                label={formatMessage(messages.balanceLabel)}
                maximumFractionDigits={2}
                name="balance"
              />
            </FirstColumn>
            <SecondColumn>
              <ReactHookFormDateField
                required={isBalanceRequired}
                label={formatMessage(messages.balanceDateLabel)}
                name="value_date"
              />
            </SecondColumn>
          </FormGrid2ColumnWithTopPadding>
          {isEdit && (
            <FormGrid2Column>
              <DoubleColumn>
                <LastSavedBalancesList data={detailsData} data-testid="last-saved-balances-list" />
              </DoubleColumn>
            </FormGrid2Column>
          )}
        </Accordion>
      </DoubleColumn>
      <CollapseGrid2Column open={Boolean(paidThroughTypeValue)}>
        <DoubleColumnWithMinHeight>
          <InfoText iconColor="gray-40">
            {formatMessage(
              paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT
                ? messages.balanceInfoBankAccount
                : messages.balanceInfoCash
            )}
          </InfoText>
        </DoubleColumnWithMinHeight>
      </CollapseGrid2Column>
      <CollapseGrid2ColumnWithMaxHeight
        open={getIsAutokasszaEnabled(provider, bankProviders) && paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT}
        as={DoubleColumn}
      >
        <DoubleColumn>
          <BlockTitleSpan>
            <Typography size="700-md" tag="h4">
              {formatMessage(messages.autokassza)}
            </Typography>
            <AutoKasszaIcon size={20} />
          </BlockTitleSpan>
        </DoubleColumn>
        <DoubleColumn>
          <AutokasszaInfoContainer>
            <InfoText iconColor="gray-40">{formatMessage(messages.autokasszaInfo)}</InfoText>
            <StyledExternalLink text={MoreInformationMessage} href={LINKS.paidThroughMoreInformation} />
          </AutokasszaInfoContainer>
        </DoubleColumn>
        <DoubleColumn>
          <IsAutokasszaCheckbox />
        </DoubleColumn>
      </CollapseGrid2ColumnWithMaxHeight>
    </FormSection>
  )
}

PureEditorFormBalanceSection.propTypes = {
  bankProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      autokassza_available: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  detailsData: PropTypes.shape({
    balances: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.string.isRequired,
        value_date: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }) as React.Validator<EditorFormBalanceSectionProps['detailsData']>,
  isEdit: PropTypes.bool.isRequired,
  provider: PropTypes.number,
}

export const EditorFormBalanceSection = connect((state: Store) => ({
  bankProviders: state.dashboard.common.bank_providers,
}))(PureEditorFormBalanceSection)

EditorFormBalanceSection.displayName = 'EditorFormBalanceSection'
