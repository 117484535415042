import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import cx from 'classnames'

import { ConnectedSelect, optionRenderer } from '@oldComponents/pages/CegjelzoPage/Fields'

export function RenderSearchField({
  classes,
  label,
  meta: { touched, error },
  texts,
  input,
  autoFocus,
  disabled,
  required,
  ...rest
}) {
  const hasError = touched && Boolean(error)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control')}
      error={hasError}
      disabled={disabled}
      required={required}
    >
      {label && (
        <InputLabel shrink className={classes.bootstrapFormLabel}>
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <ConnectedSelect
          input={input}
          disabled={disabled}
          hasError={hasError}
          optionRenderer={optionRenderer}
          {...rest}
        />
      </div>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

RenderSearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string.isRequired)]),
  }),
  texts: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}
