import React from 'react'

import { isIntegrationInvoiceWithoutNav, isNAVInvoice } from '@helpers'

export function useInvoiceDetailsControls<Data extends ExpenseDetailsFrontendValues>(
  data: Data,
  isEditorUser: boolean,
  isInvoiceProcessed?: (payload: Data) => boolean
): InvoiceDetailsControls {
  return React.useMemo(() => {
    const hasIntegration = isIntegrationInvoiceWithoutNav(data)
    const invoiceEditDisabled = isInvoiceProcessed?.(data) ?? false
    const isProcessedInvoice = isEditorUser && invoiceEditDisabled
    const isEditDisabled = !isEditorUser || isProcessedInvoice
    const isNavInvoice = isNAVInvoice(data)
    const readOnly = !isEditorUser

    return {
      hasIntegration,
      invoiceEditDisabled,
      isEditDisabled,
      isNavInvoice,
      isNotEditable: invoiceEditDisabled || hasIntegration,
      isProcessedInvoice,
      readOnly,
    }
  }, [data, isEditorUser, isInvoiceProcessed])
}
