import React from 'react'

import { FormattedMessage } from 'react-intl'

export const InvoicePartnerBlockTitleMessage = (
  <FormattedMessage id="form.invoice.blockTitle.partner" defaultMessage="Partner infó" />
)

export const InvoiceGeneralInfoBlockTitleMessage = (
  <FormattedMessage id="form.invoice.blockTitle.generalInfo" defaultMessage="Általános infó" />
)

export const InvoiceDateBlockTitleMessage = (
  <FormattedMessage id="form.invoice.blockTitle.dates" defaultMessage="Dátumok" />
)

export const CalculationBaseGrossAmountIndicatorMessage = (
  <FormattedMessage
    id="form.invoice.calculationBase.grossAmount"
    defaultMessage="A számítások alapja a bruttó összeg"
  />
)

export const CalculationBaseNetAmountIndicatorMessage = (
  <FormattedMessage id="form.invoice.calculationBase.netAmount" defaultMessage="A számítások alapja a nettó összeg" />
)

export const InvoiceCostsBlockTitleMessage = (
  <FormattedMessage id="form.invoice.blockTitle.costs" defaultMessage="Összegek" />
)

export const RemoveInvoiceButtonMessage = (
  <FormattedMessage id="buttons.incomeDetails.removeInvoice" defaultMessage="Számla törlése" />
)

export const RemoveInvoiceConfirmMessage = (
  <FormattedMessage
    id="costs.dialogText.removeExpense"
    defaultMessage="Biztosan eltávolítja a feltöltött számlát a rendszerből?"
  />
)
