import React from 'react'

import { connect } from 'react-redux'

import { authActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { INITIAL_STATE, reducer } from './reducer'
import { CompanyIntegrationListResponse, IntegrationAction, IntegrationsBlockState } from './types'

interface CompanyIntegrationsContextValue extends IntegrationsBlockState {
  updateCompanyIntegrations: (payload: CompanyIntegrationListResponse) => void
}

export const CompanyIntegrationsContext = React.createContext<Nullable<CompanyIntegrationsContextValue>>(null)

CompanyIntegrationsContext.displayName = 'CompanyIntegrationsContext'

interface CompanyIntegrationsProviderProps {
  children: JSX.Element
  loadIntegrationProviders: AsyncFunction<void, CompanyIntegrationListResponse>
}

function PureCompanyIntegrationsProvider({ children, loadIntegrationProviders }: CompanyIntegrationsProviderProps) {
  const cPromiseRef = useCancellablePromiseRef<CompanyIntegrationListResponse>()
  const [{ companyIntegrations, error, loading, fetched }, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  React.useEffect(() => {
    async function load() {
      dispatch({ type: IntegrationAction.INIT })
      try {
        cPromiseRef.current = cancellablePromise(loadIntegrationProviders())
        const { integrations } = await cPromiseRef.current.promise
        dispatch({ type: IntegrationAction.SUCCESS, payload: integrations })
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          dispatch({ type: IntegrationAction.FAILURE, payload: errorMsg })
        }
      }
    }

    load()
  }, [cPromiseRef, loadIntegrationProviders])

  const updateCompanyIntegrations = React.useCallback(({ integrations }: CompanyIntegrationListResponse) => {
    dispatch({ type: IntegrationAction.UPDATE, payload: integrations })
  }, [])

  const contextValue = React.useMemo(
    () => ({
      companyIntegrations,
      updateCompanyIntegrations,
      error,
      loading,
      fetched,
    }),
    [companyIntegrations, error, fetched, loading, updateCompanyIntegrations]
  )

  return <CompanyIntegrationsContext.Provider value={contextValue}>{children}</CompanyIntegrationsContext.Provider>
}

export const CompanyIntegrationsProvider = connect(null, dispatch => ({
  loadIntegrationProviders: bindActionToPromise(dispatch, authActions.loadIntegrationProviders.request),
}))(PureCompanyIntegrationsProvider)

CompanyIntegrationsProvider.displayName = 'CompanyIntegrationsProvider'
