import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { bankAccountNumberTransform, isAdvancedAccountingAvailable } from '@helpers'

import { LedgerNumberField } from '@components/forms/elements'
import {
  Button,
  FirstColumn,
  FormGrid2Column,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
  SecondColumn,
} from '@components/ui'

import { COMPANY_USER_PERMISSIONS, PaidThroughType } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled, permissionDeniedForUser } from '@permissions'

import { EditorFormBalanceSection } from './elements'
import { PaidThroughEditorFormContentProps, PaidThroughFormValues } from './types'

import { CancelButtonMessage, globalMessages } from '@messages'
import messages from './messages'
import { CollapseGrid2ColumnWithNoPaddingTop, FormDialogActions, FormSection } from './styles'

function getPettyCashName({
  message,
  currencies,
  currency,
}: {
  message: string
  currencies: Currency[]
  currency: Nullable<number> | undefined
}) {
  if (currency == null) {
    return ''
  }

  return `${message} ${currencies.find(({ id }) => id === currency)?.name}`
}

/**
 * Form body component for editing or creating a paid through.
 *
 * @param {PaidThroughEditorFormContentProps} {
 *   currencies,
 *   defaultCurrencyId,
 *   handleSubmit,
 *   bankProviders,
 *   isEdit,
 *   onClose,
 *   detailsData,
 * }
 * @returns
 */
export function PurePaidThroughEditorFormContent({
  bankProviders,
  currencies,
  detailsData,
  isAdvancedAccountingAvailableForUser,
  isBankTransactionsAllowed,
  isEdit,
  onClose,
}: PaidThroughEditorFormContentProps) {
  const { formatMessage } = useIntl()
  const { setValue, trigger } = useFormContext<PaidThroughFormValues>()
  const [provider, paidThroughTypeValue, balance, valueDate, currency, name, isAutokassza] = useWatch<
    PaidThroughFormValues,
    ['provider', 'paidthrough_type', 'balance', 'value_date', 'currency', 'name', 'is_autokassza']
  >({
    name: ['provider', 'paidthrough_type', 'balance', 'value_date', 'currency', 'name', 'is_autokassza'],
  })
  const prevCurrencyRef = React.useRef(null)

  const onChangePaidThroughTypeHandler = React.useCallback(
    (_field, value) => {
      const pettyCashName = getPettyCashName({ message: formatMessage(globalMessages.pettyCash), currencies, currency })

      if (value === PaidThroughType.CASH) {
        if (currency != null && !name) {
          setValue('name', pettyCashName, { shouldValidate: true })
        }

        // empty fields
        setValue('is_autokassza', false)
        setValue('provider', null)
        setValue('account_number', '', { shouldValidate: true })
      }

      if (value === PaidThroughType.BANK_ACCOUNT && name === pettyCashName) {
        setValue('name', '', { shouldValidate: true })
      }
    },
    [currencies, currency, formatMessage, name, setValue]
  )

  const onChangeCurrencyHandler = React.useCallback(
    (_field, currency) => {
      const message = formatMessage(globalMessages.pettyCash)
      const pettyCashName = getPettyCashName({ message, currencies, currency })
      const previousNameToMatch = getPettyCashName({
        message,
        currencies,
        currency: prevCurrencyRef.current,
      })

      if (
        paidThroughTypeValue === PaidThroughType.CASH &&
        currency != null &&
        (!name || name === previousNameToMatch || name === message)
      ) {
        setValue('name', pettyCashName, { shouldValidate: true })
      }
      prevCurrencyRef.current = currency
    },
    [currencies, formatMessage, name, paidThroughTypeValue, setValue]
  )

  const onChangeProviderHandler = React.useCallback(
    (_field, value) => {
      setValue('is_autokassza', false)

      if (!value) {
        setValue('account_number', '', { shouldValidate: true })
      }
    },
    [setValue]
  )

  //* set the add_new_balance field to true if both value_date and balance are filled (checkbox was removed from UI)
  React.useEffect(() => {
    setValue('add_new_balance', Boolean(balance && valueDate))
  }, [balance, valueDate, setValue])

  // trigger validation on balance and value_date fields when is_autokassza unckecked
  React.useEffect(() => {
    if (!isAutokassza) {
      trigger(['balance', 'value_date'])
    }
  }, [isAutokassza, setValue, trigger])

  const paidThroughTypeOptions = [
    { id: PaidThroughType.CASH, name: formatMessage(messages.cash) },
    { id: PaidThroughType.BANK_ACCOUNT, name: formatMessage(messages.bankAccount) },
  ]

  return (
    <>
      <FormSection as={FormGrid2Column}>
        <FirstColumn>
          <ReactHookFormSelectField
            label={formatMessage(messages.paidThroughTypeLabel)}
            labelKey="name"
            name="paidthrough_type"
            onChange={onChangePaidThroughTypeHandler}
            options={paidThroughTypeOptions}
            required
            valueKey="id"
          />
        </FirstColumn>
        <SecondColumn>
          <ReactHookFormSelectField
            label={formatMessage(messages.currencyLabel)}
            labelKey="name"
            name="currency"
            onChange={onChangeCurrencyHandler}
            options={currencies}
            required
            valueKey="id"
          />
        </SecondColumn>
        <FirstColumn>
          <ReactHookFormTextField label={formatMessage(messages.paidThroughNameLabel)} name="name" required />
        </FirstColumn>
        {isAdvancedAccountingAvailableForUser && (
          <SecondColumn>
            <LedgerNumberField
              fieldPrefix="ledger_number"
              isPaidThroughLedgerNumber
              label={formatMessage(messages.paidThroughLedgerNumberLabel)}
              placeholder="38___"
            />
          </SecondColumn>
        )}
        <CollapseGrid2ColumnWithNoPaddingTop open={paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT}>
          <FirstColumn>
            <ReactHookFormSelectField
              isClearable
              label={formatMessage(messages.providerLabel)}
              labelKey="name"
              name="provider"
              onChange={onChangeProviderHandler}
              options={bankProviders}
              placeholder={formatMessage(messages.providerPlaceholder)}
              valueKey="id"
            />
          </FirstColumn>
          <SecondColumn>
            <ReactHookFormTextField
              disabled={!provider}
              name="account_number"
              label={formatMessage(
                provider === 1 ? messages.bankAccountIdentifierLabel : messages.bankAccountNumberLabel
              )}
              required={Boolean(provider)}
              {...(provider !== 1 && { transform: bankAccountNumberTransform })} // provider 1 - Citibank
            />
          </SecondColumn>
        </CollapseGrid2ColumnWithNoPaddingTop>
      </FormSection>
      {isBankTransactionsAllowed && (
        <EditorFormBalanceSection
          data-testid="editor-form-balance-section"
          detailsData={detailsData}
          isEdit={isEdit}
          provider={provider}
        />
      )}
      <FormDialogActions>
        <ReactHookFormError withFade hideIcon />
        <div>
          <Button onClick={onClose} variant="secondaryContained" type="button">
            {CancelButtonMessage}
          </Button>
          <ReactHookFormSubmitButton
            isCreateOnly
            variant="primaryContained"
            buttonText={
              isEdit ? formatMessage(globalMessages.saveButtonText) : formatMessage(globalMessages.addButtonText)
            }
          />
        </div>
      </FormDialogActions>
    </>
  )
}

PurePaidThroughEditorFormContent.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  detailsData: PropTypes.shape({
    balances: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.string.isRequired,
        value_date: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }) as React.Validator<PaidThroughDetailData>,
  isEdit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bankProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      autokassza_available: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  isAdvancedAccountingAvailableForUser: PropTypes.bool.isRequired,
  isBankTransactionsAllowed: PropTypes.bool.isRequired,
}

export const PaidThroughEditorFormContent = connect((state: Store) => ({
  currencies: state.dashboard.common.currencies,
  defaultCurrencyId: state.auth.company.data.default_currency,
  bankProviders: state.dashboard.common.bank_providers,
  isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
  isBankTransactionsAllowed:
    isPlanPermissionEnabled(state, FeaturePermissons.BANK_TRANSACTIONS) &&
    !permissionDeniedForUser(state, COMPANY_USER_PERMISSIONS.bankTransactions),
}))(PurePaidThroughEditorFormContent)

PaidThroughEditorFormContent.displayName = 'PaidThroughEditorFormContent'
