import React from 'react'

type ReducerState = {
  invoiceFilterWarningDialog: boolean
  invoiceBulkUpdateDialog: boolean
  removeArtifactDialog: boolean
  removeArtifactLoading: boolean
  removeArtifactError: BackendError
  fulfilledAt: Nullable<string>
  bulkUpdateData: any
}

const initialState: ReducerState = {
  invoiceFilterWarningDialog: false,
  invoiceBulkUpdateDialog: false,
  removeArtifactDialog: false,
  removeArtifactLoading: false,
  removeArtifactError: null,
  fulfilledAt: null,
  bulkUpdateData: null,
}

enum ReducerActionTypes {
  OPEN_BULK_UPDATE_DIALOG = 'OPEN_BULK_UPDATE_DIALOG',
  OPEN_WARNING_DIALOG = 'OPEN_WARNING_DIALOG',
  OPEN_REMOVE_ARTIFACT_DIALOG = 'OPEN_REMOVE_ARTIFACT_DIALOG',
  TOGGLE_REMOVE_ARTIFACT_LOADING = 'TOGGLE_REMOVE_ARTIFACT_LOADING',
  SET_REMOVE_ARTIFACT_ERROR = 'SET_REMOVE_ARTIFACT_ERROR',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
}

type ReducerAction =
  | { type: ReducerActionTypes.OPEN_BULK_UPDATE_DIALOG; payload: any }
  | { type: ReducerActionTypes.OPEN_WARNING_DIALOG; payload: string }
  | { type: ReducerActionTypes.OPEN_REMOVE_ARTIFACT_DIALOG }
  | { type: ReducerActionTypes.TOGGLE_REMOVE_ARTIFACT_LOADING }
  | { type: ReducerActionTypes.SET_REMOVE_ARTIFACT_ERROR; payload: BackendError }
  | {
      type: ReducerActionTypes.CLOSE_DIALOG
      payload: 'removeArtifactDialog' | 'invoiceFilterWarningDialog' | 'invoiceBulkUpdateDialog'
    }

function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case ReducerActionTypes.OPEN_BULK_UPDATE_DIALOG:
      return {
        ...state,
        invoiceBulkUpdateDialog: true,
        bulkUpdateData: action.payload,
      }
    case ReducerActionTypes.OPEN_WARNING_DIALOG:
      return {
        ...state,
        invoiceFilterWarningDialog: true,
        fulfilledAt: action.payload,
      }
    case ReducerActionTypes.OPEN_REMOVE_ARTIFACT_DIALOG:
      return {
        ...state,
        removeArtifactDialog: true,
      }

    case ReducerActionTypes.TOGGLE_REMOVE_ARTIFACT_LOADING:
      return {
        ...state,
        removeArtifactLoading: !state.removeArtifactLoading,
      }

    case ReducerActionTypes.SET_REMOVE_ARTIFACT_ERROR:
      return {
        ...state,
        removeArtifactError: action.payload,
      }

    case ReducerActionTypes.CLOSE_DIALOG:
      return {
        ...state,
        [action.payload]: false,
      }
    default:
      return state
  }
}

export function useInvoiceDetailsRemoveControls(
  removeArtifact: AsyncFunction<{ id: ItemIdType }, unknown>,
  invoiceDetailsId: ItemIdType
) {
  const [
    {
      invoiceFilterWarningDialog,
      invoiceBulkUpdateDialog,
      fulfilledAt,
      bulkUpdateData,
      removeArtifactDialog,
      removeArtifactLoading,
      removeArtifactError,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  const closeDialog = React.useCallback(payload => {
    dispatch({ type: ReducerActionTypes.CLOSE_DIALOG, payload })
  }, [])

  const setRemoveArtifactError = React.useCallback(payload => {
    dispatch({ type: ReducerActionTypes.SET_REMOVE_ARTIFACT_ERROR, payload })
  }, [])

  const deleteArtifactHandler = React.useCallback(async () => {
    dispatch({ type: ReducerActionTypes.TOGGLE_REMOVE_ARTIFACT_LOADING })

    try {
      await removeArtifact({ id: invoiceDetailsId })
      closeDialog('removeArtifactDialog')
    } catch (error) {
      setRemoveArtifactError(error)
    }
    dispatch({ type: ReducerActionTypes.TOGGLE_REMOVE_ARTIFACT_LOADING })
  }, [closeDialog, invoiceDetailsId, removeArtifact, setRemoveArtifactError])

  // removeArtifactError handling
  React.useEffect(() => {
    let timeout: number | undefined
    if (removeArtifactError !== null) {
      timeout = window.setTimeout(setRemoveArtifactError, 3000, null)
    }
    return () => {
      window.clearTimeout(timeout)
    }
  }, [removeArtifactError, setRemoveArtifactError])

  return React.useMemo(() => {
    function openBulkUpdateDialog(payload: any) {
      dispatch({ type: ReducerActionTypes.OPEN_BULK_UPDATE_DIALOG, payload })
    }

    function openWarningDialog(payload: string) {
      dispatch({ type: ReducerActionTypes.OPEN_WARNING_DIALOG, payload })
    }

    function openRemoveArtifactDialog() {
      dispatch({ type: ReducerActionTypes.OPEN_REMOVE_ARTIFACT_DIALOG })
    }

    return {
      bulkUpdateData,
      closeDialog,
      deleteArtifactHandler,
      fulfilledAt,
      invoiceBulkUpdateDialog,
      invoiceFilterWarningDialog,
      openBulkUpdateDialog,
      openRemoveArtifactDialog,
      openWarningDialog,
      removeArtifactDialog,
      removeArtifactError,
      removeArtifactLoading,
    }
  }, [
    bulkUpdateData,
    closeDialog,
    deleteArtifactHandler,
    fulfilledAt,
    invoiceBulkUpdateDialog,
    invoiceFilterWarningDialog,
    removeArtifactDialog,
    removeArtifactError,
    removeArtifactLoading,
  ])
}
