import { CommonAxiosPayload } from '@services/types'

import { TaxationTaskData } from './TaxationTasks/types'
import { VatPeriodOptions } from './VatPositionReportAccordion/types'

export enum PulseDialogs {
  WELCOME_TO_TASK_LIST = 'welcomeToTaskList',
}

export enum ReportNames {
  BANK_TRANSACTIONS = 'bank-transactions',
  VAT_POSITION = 'vat-position',
  WORKOUT = 'workout',
  SETUP_TASK_LIST = 'setup-task-list',
  TAXATION_AAM = 'taxation-aam',
  TAXATION_KATA = 'taxation-kata',
  TAXATION_ATALANY = 'taxation-atalany',
  TAXATION_TAX_CALCULATION = 'taxation-tax-calculation',
  TAXATION_TASK = 'taxation-task',
  SPLITTER = 'splitter',
  DISCLAIMER = 'disclaimer',
  KPI_WIDGET = 'kpi-widget',
  PULSE_MONTHLY_CHART = 'pulse-monthly-chart',
  QUIC_CHAT = 'quick-chat',
}

export enum SetupTaskNames {
  CONNECT_INTEGRATION = 'connect-integration',
  INVITE_ACCOUNTANT = 'invite-accountant',
  SETUP_TAXATION_AAM = 'setup-taxation-aam',
  SETUP_TAXATION_TAX = 'setup-taxation-tax-form',
}

interface BackendBankTransactionsReport {
  data: { summary: number; show_promo: boolean }
  id: string | number
  name: ReportNames.BANK_TRANSACTIONS
}

interface BackendVatPositionReport {
  data: { [key in VatPeriodOptions]: { vat: number } }
  id: string | number
  name: ReportNames.VAT_POSITION
}

interface BackendWorkoutReport {
  data: { outstanding_amount: Nullable<number>; expired_amount: number; expired_percent: Nullable<number> }
  id: string | number
  name: ReportNames.WORKOUT
}

interface BackendTaxationTask {
  data: TaxationTaskData
  id: string | number
  name: ReportNames.TAXATION_TASK
}

export interface BackendSetupTaskCommonProps {
  metadata: unknown // we will always have this, but might be empty object (ie. when status === 'disabled')
  status: 'current' | 'skipped' | 'completed' | 'disabled'
  links: {
    complete?: string
    skip?: string
  } // it will be an empty object if status === 'disabled'
}

export interface BackendConnectIntegrationTask extends BackendSetupTaskCommonProps {
  name: SetupTaskNames.CONNECT_INTEGRATION
  metadata: {
    company_type: string
    is_szamlazz_connected: boolean
    is_billingo_connected: boolean
  }
}

export interface BackendInviteAccountantTask extends BackendSetupTaskCommonProps {
  name: SetupTaskNames.INVITE_ACCOUNTANT
  links: {
    complete: string
    skip: string
  }
}

export interface BackendSetupTaxationAamTask extends BackendSetupTaskCommonProps {
  name: SetupTaskNames.SETUP_TAXATION_AAM
  metadata: {
    founded_at: Nullable<string>
    tax_number: string
  }
}

export interface BackendSetupTaxationTaxTask extends BackendSetupTaskCommonProps {
  name: SetupTaskNames.SETUP_TAXATION_TAX
  metadata: {
    atalany_cost_percentage?: Nullable<string>
    atalany_employment_type?: Nullable<string>
    atalany_qualification_required?: Nullable<boolean>
    savos_ipa?: Nullable<boolean>
    savos_ipa_type?: Nullable<number>
    start_date?: Nullable<string>
    tax_form?: Nullable<string>
  }
}

export type BackendSetupTask =
  | BackendConnectIntegrationTask
  | BackendInviteAccountantTask
  | BackendSetupTaxationAamTask
  | BackendSetupTaxationTaxTask

interface BackendSetupTaskList {
  data: {
    tasks: BackendSetupTask[]
  }
  id: string | number
  name: ReportNames.SETUP_TASK_LIST
}

export interface PulseSplitter {
  data: string | number
  id: string | number
  name: ReportNames.SPLITTER
}

export interface PulseDisclaimer {
  data: string
  id: string | number
  name: ReportNames.DISCLAIMER
}

export type BackendReportData =
  | BackendVatPositionReport
  | BackendBankTransactionsReport
  | BackendWorkoutReport
  | BackendSetupTaskList
  | PulseSplitter
  | PulseDisclaimer
  | BackendTaxationTask

export type BackendReportResults = {
  links: {
    more_reports: BackendCallableLink
  }
  reports: BackendReportData[]
}

export interface FetchReportsHookProps {
  callUrl: AsyncFunction<CommonAxiosPayload<void>, BackendReportResults>
  fetchReports: AsyncFunction<void, BackendReportResults>
}

interface ReportAccordionProps {
  companyCurrency: string
  refetchReports: VoidFunction
  updatedAt: string
}

export interface BankTransactionsReportAccordionProps extends ReportAccordionProps {
  report: BackendBankTransactionsReport
}

export interface VatPositionReportAccordionProps extends ReportAccordionProps {
  report: BackendVatPositionReport
}

export interface WorkoutReportAccordionProps extends ReportAccordionProps {
  report: BackendWorkoutReport
}

export interface SetupTaskListProps extends ReportAccordionProps {
  closeTask: AsyncFunction<CommonAxiosPayload<unknown>, BackendSetupTaskList['data']>
  report: BackendSetupTaskList
}

interface TaxationReportDetails {
  links: { details: string }
}

//* Taxation placeholders
interface BackendTaxationAamReport extends TaxationReportDetails {
  name: ReportNames.TAXATION_AAM
  data: {
    aam_reducing: number
    aam_reducing_pct: number
    aam_remaining: number
    is_current: boolean
    year: number
  }
}

interface BackendTaxationKataReport extends TaxationReportDetails {
  name: ReportNames.TAXATION_KATA
  data: {
    kata_reducing: number
    kata_reducing_pct: number
    kata_remaining: number
    is_current: boolean
    year: number
  }
}

interface BackendTaxationAtalanyReport extends TaxationReportDetails {
  name: ReportNames.TAXATION_ATALANY
  data: {
    atalany_reducing: number
    atalany_reducing_pct: number
    atalany_remaining: number
    is_current: boolean
    year: number
  }
}

export type ReportYearlyLink = { url: string; year: number }

interface BackendTaxationTaxCalculationReport {
  name: ReportNames.TAXATION_TAX_CALCULATION
  data: {
    current_date: string
    payable_tax: number
    year: number
  }
  links: {
    monthly: ReportYearlyLink[]
    payable: ReportYearlyLink[]
  }
}

export interface TaxationAamAccordionProps extends ReportAccordionProps {
  report: BackendTaxationAamReport
}

export interface TaxationKataAccordionProps extends ReportAccordionProps {
  report: BackendTaxationKataReport
}

export interface TaxationAtalanyAccordionProps extends ReportAccordionProps {
  report: BackendTaxationAtalanyReport
}

export interface TaxationTaxCalculationAccordionProps extends ReportAccordionProps {
  locale: Locale
  report: BackendTaxationTaxCalculationReport
}

export enum PulseMonthlyChartDisplayType {
  GROSS = 'gross',
  NET = 'net',
}

export interface BackendPulseMonthlyChartReport {
  name: ReportNames.PULSE_MONTHLY_CHART
  data: { display: PulseMonthlyChartDisplayType }
  links: {
    gross: ReportYearlyLink
    net: Nullable<ReportYearlyLink>
  }
}

export interface PulseMonthlyChartAccordionProps extends ReportAccordionProps {
  report: BackendPulseMonthlyChartReport
}

//* DYNAMIC TITLE VALUES

export type TaxationTitleValues = {
  isCurrent: boolean
  reducingPercent: number
  remainingAmount: number
  usedAmount: number
  year: number
}

export type WorkoutTitleValues = {
  amount: number
  expiredPercent: number
}

export type BankTransactionsTitleValues = {
  amount: number
}

export type TaxationTaxCalculationTitleValues = {
  currentDate: string
  payableTax: Decimal | number
}

export interface IncomeRegister {
  params: {
    start_date: string
    end_date: string
  }
}

export interface PulseKPIWidget {
  name: ReportNames.KPI_WIDGET
  data: {
    operation_results: {
      expense: number
      income: number
      total: number
    }
    outstandings: {
      expense_outstanding: number
      income_outstanding: number
      total: number
    }
    vat: Nullable<{
      expense_vat: number
      income_vat: number
      total: number
      vat_period: 'monthly' | 'quarterly' | 'yearly'
    }>
  }
}

//* CHAT
export type QuickChatData = {
  name: ReportNames.QUIC_CHAT
  data: unknown // not used yet
  links: {
    chat: string
  }
}

export interface QuickChatAccordionProps extends ReportAccordionProps {
  report: QuickChatData
}
