import React from 'react'
import PropTypes from 'prop-types'

import { Button, Collapse, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, Form, FormProps } from 'react-final-form'
import { defineMessages, useIntl } from 'react-intl'
import * as yup from 'yup'

import { makeValidate } from '@helpers'

import { SubmitButton } from '@oldComponents/ui'
import { RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION } from '@constants/detailsForm'

import { AttachmentUploadFormValues } from '../types'
import { AttachementTypeSelector, AttachmentType } from './AttachementTypeSelector'
import { AttachmentFileField } from './AttachmentFileField'

import { formErrorMessages, ResetButtonMessage } from '@messages'
import styles, { SPACING } from '@oldComponents/forms/DetailsForm/styles'

const messages = defineMessages({
  attachementNameLabel: {
    id: 'form.label.attachementName',
    defaultMessage: 'Csatolmány neve',
  },
  attachementTypeLabel: {
    id: 'form.label.attachementType',
    defaultMessage: 'Csatolmány típusa',
  },
  webLinkLabel: {
    id: 'form.label.webLink',
    defaultMessage: 'Web link URL',
  },
  saveButtonText: {
    id: 'form.button.save',
    defaultMessage: 'Mentés',
  },
  savingText: {
    id: 'form.button.savingText',
    defaultMessage: 'Mentés folyamatban...',
  },
  requiredType: {
    id: 'form.errors.attachementType.required',
    defaultMessage: 'A típus kiválasztása kötelező',
  },
  requiredURL: {
    id: 'form.errors.requiredURL',
    defaultMessage: 'Érvénytelen web link',
  },
  requiredFile: {
    id: 'form.errors.requiredFile',
    defaultMessage: 'A fájl kiválasztása kötelező',
  },
})

const useStyles = makeStyles(styles)

export function AttachmentForm(props: FormProps<AttachmentUploadFormValues>) {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const yupValidatorRef = React.useRef(
    makeValidate(
      yup.object().shape({
        title: yup.string().required(formatMessage(formErrorMessages.required)),
        type: yup.string().required(formatMessage(messages.requiredType)),
        link: yup.string().when(['type'], {
          is: (value: Nullable<AttachmentType>) => value === AttachmentType.LINK,
          then: yup
            .string()
            .url(formatMessage(messages.requiredURL))
            .required(formatMessage(formErrorMessages.required)),
        }),
        file: yup.mixed().when(['type'], {
          is: (value: Nullable<AttachmentType>) => value === AttachmentType.FILE,
          then: yup.mixed().required(formatMessage(messages.requiredFile)),
        }),
      })
    )
  )

  return (
    <Form {...props} validate={yupValidatorRef.current}>
      {({
        dirtySinceLastSubmit, // need to control submitError display
        error, // sync error
        submitError, // async error
        handleSubmit,
        form: { restart },
        pristine,
        submitting,
        values,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
          <div className={classes.formBlock}>
            <Grid container spacing={SPACING}>
              <Grid item md={7} lg={8}>
                <Field
                  component={RenderTextField}
                  label={formatMessage(messages.attachementNameLabel)}
                  name="title"
                  required
                  subscription={FIELD_SUBSCRIPTION}
                />
              </Grid>
              <Grid item md={5} lg={4}>
                <AttachementTypeSelector
                  classes={classes}
                  label={formatMessage(messages.attachementTypeLabel)}
                  name="type"
                />
              </Grid>
            </Grid>
            <Grid container spacing={SPACING}>
              <Grid item xs={12}>
                <Collapse in={values.type === AttachmentType.LINK}>
                  <Field
                    component={RenderTextField}
                    label={formatMessage(messages.webLinkLabel)}
                    name="link"
                    placeholder="http://example.com"
                    subscription={FIELD_SUBSCRIPTION}
                  />
                </Collapse>
                <Collapse in={values.type === 'file'}>
                  <AttachmentFileField />
                </Collapse>
              </Grid>
            </Grid>
          </div>
          {(error || (!dirtySinceLastSubmit && submitError)) && (
            <div style={{ marginTop: '1rem' }} data-test-id="error">
              <Typography color="error" align="left" component="div">
                {error || submitError}
              </Typography>
            </div>
          )}
          <Grid container className={classes.detailsFormButtonsContainer} alignItems="flex-end" spacing={SPACING}>
            <Grid item>
              <Button
                type="button"
                color="primary"
                variant="outlined"
                onClick={() => {
                  restart()
                }}
                disabled={pristine}
              >
                {ResetButtonMessage}
              </Button>
            </Grid>

            <Grid item>
              <SubmitButton
                loading={submitting}
                loadingText={formatMessage(messages.savingText)}
                variant={pristine ? 'outlined' : 'contained'}
                buttonText={formatMessage(messages.saveButtonText)}
                disabled={pristine}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
}

AttachmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}
