import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { authActions } from '@services'

import { AMPLITUDE_EVENTS, sendAmplitudeData } from '@helpers'
import { getRouteUrl } from '@helpers/url'

import { CustomDialog, CustomDialogHeader } from '@components/ui'

import { RouteKeys } from '@constants'

import { CreateCompanyForm, CreateCompanySuccessView } from './elements'

const StyledDialog = styled(CustomDialog)`
  width: 528px;
`

interface CreateCompanyDialogProps {
  callSelectCompany: (companyId: number) => void
  createdCompany: Nullable<Company>
  isOpen: boolean
  onClose: VoidFunction
  onSubmitSuccessHandler: (payload: Company) => void
}

function PureCreateCompanyDialog({
  callSelectCompany,
  createdCompany,
  isOpen,
  onClose,
  onSubmitSuccessHandler,
}: CreateCompanyDialogProps) {
  const navigate = useNavigate()

  const onCloseHandler = React.useCallback(() => {
    if (createdCompany) {
      callSelectCompany(createdCompany.id)

      // redirect user to transaction route with new company id - DashboardCompanyRoutes will take care of the rest
      const url = getRouteUrl(RouteKeys.ROOT, createdCompany.id)
      // redirect to new company url
      navigate(url)

      // send amplitude event
      sendAmplitudeData(AMPLITUDE_EVENTS.NEW_COMPANY_CREATED_CLOSE)
    }

    onClose()
  }, [callSelectCompany, createdCompany, navigate, onClose])

  const goToIntegrationSettingsHandler = React.useCallback(() => {
    if (createdCompany) {
      callSelectCompany(createdCompany.id)

      // redirect user to company settings route (scroll to integrations section) with new company id - DashboardCompanyRoutes will take care of the rest
      const url = `${getRouteUrl(RouteKeys.COMPANY, createdCompany.id)}/#integrations`
      navigate(url)

      // send amplitude event
      sendAmplitudeData(AMPLITUDE_EVENTS.NEW_COMPANY_CREATED_INTEGRATIONS)
    }

    onClose()
  }, [callSelectCompany, createdCompany, navigate, onClose])

  const goToExpenseCreateHandler = React.useCallback(() => {
    if (createdCompany) {
      callSelectCompany(createdCompany.id)

      // redirect user to create expense route with new company id - DashboardCompanyRoutes will take care of the rest
      const url = getRouteUrl(RouteKeys.COST, createdCompany.id)
      navigate(url)

      // send amplitude event
      sendAmplitudeData(AMPLITUDE_EVENTS.NEW_COMPANY_CREATED_EXPENSE)
    }

    onClose()
  }, [callSelectCompany, createdCompany, navigate, onClose])

  return (
    <StyledDialog open={isOpen} onClose={onCloseHandler}>
      <CustomDialogHeader
        borderless={Boolean(createdCompany)}
        title={
          createdCompany ? (
            <FormattedMessage id="dialogs.company.createSuccess.title" defaultMessage="Üdvözlünk a QUiCK-ben!" />
          ) : (
            <FormattedMessage id="dialogs.company.title" defaultMessage="Új cég létrehozása" />
          )
        }
      />
      {createdCompany ? (
        <CreateCompanySuccessView
          createdCompanyName={createdCompany.name}
          goToIntegrationSettingsHandler={goToIntegrationSettingsHandler}
          goToExpenseCreateHandler={goToExpenseCreateHandler}
        />
      ) : (
        <CreateCompanyForm onSubmitSuccess={onSubmitSuccessHandler} />
      )}
    </StyledDialog>
  )
}

PureCreateCompanyDialog.propTypes = {
  callSelectCompany: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccessHandler: PropTypes.func.isRequired,
}

export const CreateCompanyDialog = connect(null, {
  callSelectCompany: authActions.selectCompany.request,
})(PureCreateCompanyDialog)

CreateCompanyDialog.displayName = 'CreateCompanyDialog'
