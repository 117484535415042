import React from 'react'

import { useFormState } from 'react-hook-form'

import { useIncomeDetailsControlsDispatch } from '@contexts'

export function SyncFormWithIncomeDetailsControls() {
  const { setFormSubmittingInProgress } = useIncomeDetailsControlsDispatch()
  const { isSubmitting } = useFormState()

  React.useEffect(() => {
    setFormSubmittingInProgress(isSubmitting)
  }, [isSubmitting, setFormSubmittingInProgress])

  return null
}
