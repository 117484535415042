import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

import { isFieldHighlighted } from '@helpers'

import { ExpenseDetailsFormValues } from '@components/forms/ExpenseDetailsForm/types'
import { Typography } from '@components/ui'

import { LedgerNumberCreatableSelect, LedgerNumberCreatableSelectProps } from './LedgerNumberCreatableSelect'
import { SelectOptionData } from './types'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

type LedgerNumberFieldKey =
  | 'ledger_number'
  | `assignments.${number}.ledger_number`
  | `assignments.${number}.vat_ledger_number`

type LedgerNumberCodeFieldKey =
  | 'ledger_number_code'
  | `assignments.${number}.ledger_number_code`
  | `assignments.${number}.vat_ledger_number_code`

type LedgerNumberIdFieldKey =
  | 'ledger_number_id'
  | `assignments.${number}.ledger_number_id`
  | `assignments.${number}.vat_ledger_number_id`

type LedgerNumberNameFieldKey =
  | 'ledger_number_name'
  | `assignments.${number}.ledger_number_name`
  | `assignments.${number}.vat_ledger_number_name`

export interface LedgerNumberFieldProps
  extends Pick<
    LedgerNumberCreatableSelectProps,
    'className' | 'disabled' | 'isPaidThroughLedgerNumber' | 'placeholder' | 'recommendations' | 'suggestion'
  > {
  highlighted?: boolean
  isLabelHighlighted?: boolean
  label?: React.ReactNode
  fieldPrefix: LedgerNumberFieldKey
}

export function LedgerNumberField({
  className,
  disabled = false,
  fieldPrefix,
  highlighted = false,
  isLabelHighlighted = false,
  isPaidThroughLedgerNumber = false,
  label,
  placeholder,
  recommendations,
  suggestion,
}: LedgerNumberFieldProps) {
  const classes = useStyles()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext<ExpenseDetailsFormValues>() // this field is only used in expense details form so it's safe to use ExpenseDetailsFormValues
  const codeFieldName: LedgerNumberCodeFieldKey = `${fieldPrefix}_code`
  const {
    field: nameField,
    fieldState: { error: nameError },
  } = useController<ExpenseDetailsFormValues, LedgerNumberNameFieldKey>({ control, name: `${fieldPrefix}_name` })
  const {
    field: codeField,
    fieldState: { error: codeError },
  } = useController<ExpenseDetailsFormValues, LedgerNumberCodeFieldKey>({ control, name: codeFieldName })
  const {
    field: idField,
    fieldState: { error: idError },
  } = useController<ExpenseDetailsFormValues, LedgerNumberIdFieldKey>({ control, name: `${fieldPrefix}_id` })

  const error = codeError || nameError || idError

  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting

  const ledgerNumberCode = codeField.value || null
  const ledgerNumberName = nameField.value || ''

  const value = [ledgerNumberCode, ledgerNumberName].filter(v => v).join(' ')

  const handleChange = React.useCallback(
    (optionValue: Nullable<SelectOptionData>) => {
      codeField.onChange(optionValue?.code ?? null)
      nameField.onChange(optionValue?.name ?? null)

      if (!isPaidThroughLedgerNumber) {
        idField.onChange(optionValue?.id ?? null)
      }
    },
    [codeField, idField, isPaidThroughLedgerNumber, nameField]
  )

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(className, classes.selectRoot, 'form-control', {
        'form-control-error': hasError,
      })}
      error={hasError}
      disabled={isFieldDisabled}
    >
      {label && (
        <InputLabel
          htmlFor={codeFieldName}
          shrink
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
        >
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <LedgerNumberCreatableSelect
          className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
          disabled={disabled}
          isPaidThroughLedgerNumber={isPaidThroughLedgerNumber}
          name={codeFieldName}
          onChange={handleChange}
          placeholder={placeholder}
          recommendations={recommendations}
          suggestion={suggestion}
          value={ledgerNumberCode}
        />
        {ledgerNumberName && (
          <Typography size="400-xs" italic>
            {ledgerNumberName}
          </Typography>
        )}
      </div>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}
