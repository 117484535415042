import __orderBy from 'lodash/orderBy'
import { GroupBase } from 'react-select'

import { SelectOption } from './types'

export function getValueComponents(inputValue: string): Pick<LedgerNumber, 'code' | 'name'> {
  const [code, ...texts] = inputValue.split(' ')
  const name = texts.join(' ') || null
  return {
    code,
    name,
  }
}

export function createSingleOption(
  code: LedgerNumberFormValue['code'],
  name: LedgerNumberFormValue['name'] = null,
  id: LedgerNumberFormValue['id'] = undefined
): SelectOption {
  return {
    value: code,
    label: code,
    data: { code, id, name },
  }
}

export function createOptions(options: LedgerNumberFormValue[]): SelectOption[] {
  return __orderBy(
    options.map(option => createSingleOption(option.code, option.name, option.id)),
    item => item.value,
    'asc'
  )
}

export function getNewOptionData(inputValue: string, optionLabel: React.ReactNode): SelectOption {
  const { code, name } = getValueComponents(inputValue)
  return {
    value: code,
    label: String(optionLabel),
    data: { code, name, isNew: true },
  }
}

/**
 * Compare the code of the inputValue with the code of the option.
 * Only compare the code because it is unique on backend and can not create two ledger number with the same code.
 *
 * @param {string} inputValue - the value of the input field (typed in by the user)
 * @param {SelectOption} option - the option to compare with
 * @returns - true if the code of the inputValue is the same as the code of the option
 */
export function compareOption(inputValue: string, option: SelectOption) {
  const { code } = getValueComponents(inputValue)
  return code.toLowerCase() === option.data.code.toLowerCase()
}

export function groupedCompareOption(inputValue: string, option: SelectOption | GroupBase<SelectOption>) {
  if ('options' in option) {
    return option.options.some(opt => compareOption(inputValue, opt))
  }
  return compareOption(inputValue, option)
}
