import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import { defineMessage, useIntl } from 'react-intl'
import Select from 'react-select'

import { selectFormStyles as customReactSelectStyles } from '@styles/select'
import { formSelectMessages } from '@messages'

const NoResultsTextMessage = defineMessage({
  id: 'form.companyVatCategory.noOptions',
  defaultMessage: 'A kiválaszott területhez és ÁFA-kulcshoz nincs találat',
})

export type SelectOptionData = { name: string; code?: Nullable<string>; id?: Nullable<number> }
type SelectOption = { value: string; label: React.ReactNode; data: SelectOptionData }

function createSingleOption(
  name: string,
  code: Nullable<string> | undefined = null,
  id: Nullable<number> | undefined = null
): SelectOption {
  return {
    value: name,
    label: name,
    data: { code, id, name },
  }
}

interface CompanyVatCategorySelectProps {
  className?: string
  disabled?: boolean
  name: string
  onChange: (value: Nullable<SelectOptionData>) => void
  options: CompanyVatCategory[]
  value: Nullable<string>
}

export function CompanyVatCategorySelect({
  className,
  disabled,
  name,
  onChange,
  options,
  value,
}: CompanyVatCategorySelectProps) {
  const { formatMessage } = useIntl()

  const selectValue = value ? createSingleOption(value) : null

  const handleChange = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option' && option) {
        onChange(option.data) // set to form field
      }
      if (action === 'clear') {
        onChange(null) // set to form fields
      }
    },
    [onChange]
  )

  const noOptionsMessage = React.useCallback(() => {
    return formatMessage(NoResultsTextMessage)
  }, [formatMessage])

  const selectOptions = React.useMemo(
    () => options.map(option => createSingleOption(option.name, option.code, option.id)),
    [options]
  )

  return (
    <Select
      className={className}
      classNamePrefix="react-select"
      inputId={name}
      instanceId={__uniqueId('rs')}
      isClearable
      isDisabled={disabled}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      options={selectOptions}
      placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
      styles={customReactSelectStyles}
      value={selectValue}
    />
  )
}
