import React from 'react'

import { useWatch } from 'react-hook-form'

import { InvoiceType } from '@constants'

import { MAIN_LEDGER_NUMBER_SUGGESTIONS } from './constants'
import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'

interface MainLedgerNumberFieldProps
  extends Pick<LedgerNumberFieldProps, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'label'> {
  invoiceType?: InvoiceType
}

export function MainLedgerNumberField({ invoiceType = InvoiceType.EXPENSE, ...rest }: MainLedgerNumberFieldProps) {
  const vatArea = useWatch({ name: 'vat_area' })

  return (
    <LedgerNumberField
      {...rest}
      fieldPrefix="ledger_number"
      suggestion={MAIN_LEDGER_NUMBER_SUGGESTIONS[invoiceType][vatArea]}
    />
  )
}
