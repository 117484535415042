import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Button, CustomDialogActions, CustomDialogBody, Typography } from '@components/ui'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StyledCustomDialogActions = styled(CustomDialogActions)`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 8px;

  & button {
    min-width: 110px;
  }
`

interface CreateCompanySuccessViewProps {
  createdCompanyName: string
  goToExpenseCreateHandler: VoidFunction
  goToIntegrationSettingsHandler: VoidFunction
}

export function CreateCompanySuccessView({
  createdCompanyName,
  goToExpenseCreateHandler,
  goToIntegrationSettingsHandler,
}: CreateCompanySuccessViewProps) {
  return (
    <>
      <StyledCustomDialogBody>
        <Typography align="center">
          <FormattedMessage
            id="dialogs.company.createSuccess.text"
            defaultMessage="Sikeresen regisztráltad a(z) {company} céget."
            values={{ company: <b>{createdCompanyName}</b> }}
          />
        </Typography>
        <Typography align="center">
          <FormattedMessage
            id="dialogs.company.createSuccess.description"
            defaultMessage="Ahhoz, hogy a QUiCK a lehető legjobban segíthesse a vállalkozásod, érdemes most bekapcsolnod az integrációkat. Ezáltal a tárgyhó és az azt megelőző hónap számlái automatikusan szinkronizálódnak, így valós adatokkal fedezheted fel a funkciókat."
          />
        </Typography>
      </StyledCustomDialogBody>
      <StyledCustomDialogActions borderless>
        <Button variant="primaryContained" onClick={goToIntegrationSettingsHandler}>
          <FormattedMessage
            id="dialogs.company.createSuccess.buttons.integrationSettings"
            defaultMessage="Tovább az integrációkhoz"
          />
        </Button>
        <Button variant="primaryText" onClick={goToExpenseCreateHandler}>
          <FormattedMessage
            id="dialogs.company.createSuccess.buttons.expenseCreate"
            defaultMessage="Kipróbálom a számlakép felismerést"
          />
        </Button>
      </StyledCustomDialogActions>
    </>
  )
}

CreateCompanySuccessView.propTypes = {
  createdCompanyName: PropTypes.string,
  goToExpenseCreateHandler: PropTypes.func.isRequired,
  goToIntegrationSettingsHandler: PropTypes.func.isRequired,
}
