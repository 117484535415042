import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { AsyncButton } from '@components/ui'
import { AsyncButtonProps } from '@components/ui/Buttons/AsyncButton'

const StyledAsyncButton = styled(AsyncButton)`
  margin: 0 auto;
`

export function LoadPreviousReportsButton({ loading, onClick }: Pick<AsyncButtonProps, 'loading' | 'onClick'>) {
  return (
    <StyledAsyncButton onClick={onClick} loading={loading} variant="secondaryText">
      <FormattedMessage id="pulse.buttons.loadPreviousItems" defaultMessage="Korábbiak betöltése" />
    </StyledAsyncButton>
  )
}
