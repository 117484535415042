import React from 'react'
import PropTypes from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

import { CreateCompanyDialog } from '@components/dialogs'

const CreateCompanyDialogContext = React.createContext<{ open: VoidFunction } | undefined>(undefined)
CreateCompanyDialogContext.displayName = 'CreateCompanyDialogContext'

interface CreateCompanyDialogProviderProps {
  children: React.ReactNode
}

export function CreateCompanyDialogProvider({ children }: CreateCompanyDialogProviderProps) {
  const [isOpen, setOpen] = React.useState(false)
  const [createdCompany, setCreatedCompany] = React.useState<Nullable<Company>>(null)

  function onOpen() {
    if (createdCompany != null) {
      setCreatedCompany(null)
    }
    setOpen(true)
  }

  function onClose() {
    setOpen(false)
  }

  function onSubmitSuccessHandler(company: Company) {
    setCreatedCompany(company)
  }

  return (
    <CreateCompanyDialogContext.Provider
      value={{
        open: onOpen,
      }}
    >
      <>
        {children}
        <CreateCompanyDialog
          createdCompany={createdCompany}
          isOpen={isOpen}
          onClose={onClose}
          onSubmitSuccessHandler={onSubmitSuccessHandler}
        />
      </>
    </CreateCompanyDialogContext.Provider>
  )
}

CreateCompanyDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useCreateCompanyDialog() {
  return useAssertContext(CreateCompanyDialogContext)
}
