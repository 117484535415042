import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { isAdvancedAccountingAvailable } from '@helpers'

import { ReactHookFormSelectField } from '@components/ui'
import { ReactHookFormSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSelectField'

import { InvoiceType } from '@constants'

import { MAIN_LEDGER_NUMBER_SUGGESTIONS } from './LedgerNumberFields'

import messages from '../messages'

interface VatAreaFieldProps
  extends Pick<ReactHookFormSelectFieldProps<VatArea>, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'options'> {
  invoiceType: InvoiceType
  isAdvancedAccountingAvailableForUser: boolean
  ledgerNumberOptions: LedgerNumber[]
}

function PureVatAreaField({
  invoiceType,
  isAdvancedAccountingAvailableForUser,
  ledgerNumberOptions,
  ...rest
}: VatAreaFieldProps) {
  const { formatMessage } = useIntl()
  const { setValue, getValues } = useFormContext<ExpenseDetailsFormInitialValues>()

  const onChangeHandler = React.useCallback(
    (_fieldName, value) => {
      if (invoiceType === InvoiceType.EXPENSE && isAdvancedAccountingAvailableForUser) {
        if (value === '3RD') {
          setValue('reverse_tax_subject', true)
        }

        // clear company_vat_category for all assignments
        getValues('assignments').forEach((_assignment, index) => {
          setValue(`assignments.${index}.company_vat_category_code`, null)
          setValue(`assignments.${index}.company_vat_category_id`, null)
          setValue(`assignments.${index}.company_vat_category_name`, null)
        })

        // add ledger_number suggestion based on vat_area
        const suggestion = MAIN_LEDGER_NUMBER_SUGGESTIONS[invoiceType][value] ?? null
        if (suggestion) {
          const existingLedgerNumber = ledgerNumberOptions.find(option => option.code === suggestion.code)
          setValue('ledger_number_code', existingLedgerNumber?.code ?? suggestion.code)
          setValue('ledger_number_id', existingLedgerNumber?.id ?? null)
          setValue('ledger_number_name', existingLedgerNumber?.name ?? suggestion.name)
        }
      }
    },
    [getValues, invoiceType, isAdvancedAccountingAvailableForUser, ledgerNumberOptions, setValue]
  )

  return (
    <ReactHookFormSelectField
      {...rest}
      label={formatMessage(messages.vatAreaLabel)}
      labelKey="label"
      name="vat_area"
      onChange={onChangeHandler}
      valueKey="value"
    />
  )
}

export const VatAreaField = connect((state: Store) => ({
  isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
  ledgerNumberOptions: state.dashboard.ledgerNumbers,
  options: state.dashboard.common.vat_areas,
}))(PureVatAreaField)

VatAreaField.displayName = 'VatAreaField'
