import React from 'react'

import { PartnerEditorState } from '@oldComponents/PartnerEditor/types'

export function useInvoiceDetailsPartnerEditor() {
  const [editor, setEditor] = React.useState<PartnerEditorState>({
    data: null,
    open: false,
  })

  const open = React.useCallback(
    (newOption, callback) => {
      setEditor({
        open: true,
        data: { name: newOption.name },
        callback,
      })
    },
    [setEditor]
  )

  const close = React.useCallback(() => {
    setEditor(prevState => ({
      ...prevState,
      open: false,
    }))
  }, [setEditor])

  return {
    editor,
    open,
    close,
  }
}
