import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { commonActions, reportsActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { CenteredPageError, CenteredPageLoader } from '@components/ui'

import { FetchReportsHookProps } from '../types'
import { useFetchReports } from '../useFetchReports'
import { LoadPreviousReportsButton } from './LoadPreviousReportsButton'
import { ReportsContainer } from './ReportsContainer'

import { PageTitleTypography } from './styles'

function PurePulsePageContent(props: FetchReportsHookProps) {
  const { error, hasMore, initializing, loading, loadMore, refetch, reports, updatedAt } = useFetchReports(props)

  if (initializing) {
    return (
      <CenteredPageLoader>
        <FormattedMessage id="screen.pulse.loading" defaultMessage="Adatok betöltése folyamatban..." />
      </CenteredPageLoader>
    )
  }

  if (error) {
    return (
      <CenteredPageError
        title={
          <FormattedMessage id="screen.pulse.failure" defaultMessage="Sajnáljuk! Az adatok betöltése sikertelen." />
        }
        errorMessage={error}
      />
    )
  }

  return (
    <>
      <PageTitleTypography>
        <FormattedMessage id="pages.pulse.title" defaultMessage="Helló, nézzük a legfontosabbakat!" />
      </PageTitleTypography>
      <ReportsContainer reports={reports} refetch={refetch} updatedAt={updatedAt} />
      {hasMore && <LoadPreviousReportsButton loading={loading} onClick={loadMore} />}
    </>
  )
}

PurePulsePageContent.propTypes = {
  fetchReports: PropTypes.func.isRequired,
}

export const PulsePageContent = connect(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
  fetchReports: bindActionToPromise(dispatch, reportsActions.fetchReports.request),
}))(PurePulsePageContent)

PulsePageContent.displayName = 'PulsePageContent'
