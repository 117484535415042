import { FileError } from 'react-dropzone'

import { CustomValidatorCodes } from '@constants'

export function zeroFileSizeValidator<UploadedFile extends File>(file: UploadedFile): Nullable<FileError> {
  if (file.size === 0) {
    return {
      code: CustomValidatorCodes.ZERO_FILE_SIZE,
      message: 'Invalid file size.', // will be translated when display error in UploadedFile component
    }
  }
  return null
}

export function readFile(fileReference: Blob): Promise<{ url: string; file: Blob }> {
  return new Promise((resolve, reject) => {
    if (fileReference) {
      const reader = new FileReader()

      reader.onload = event => {
        resolve({ url: event.target?.result as string, file: fileReference })
      }
      reader.onerror = () => {
        reject('FileReader error')
      }

      reader.readAsDataURL(fileReference)
    } else {
      reject('Missing file reference')
    }
  })
}
