import { CompanyIntegrationProviders } from '@constants'

export enum IntegrationAction {
  INIT = 'init',
  SUCCESS = 'success',
  FAILURE = 'failure',
  UPDATE = 'update',
}

type CompanyIntegration<IntegrationType, IntegrationDataType> = {
  data: Nullable<IntegrationDataType>
  help_text: Nullable<string>
  integration: IntegrationType
  is_connected: boolean
  is_available: boolean
  need_help: boolean
}

export type AcountoIntegrationProvider = CompanyIntegration<
  CompanyIntegrationProviders.acounto,
  {
    acounto_username: Nullable<string>
    id: number
  }
>

export type BillingoIntegrationProvider = CompanyIntegration<
  CompanyIntegrationProviders.billingo,
  {
    billingo_private_key: Nullable<string>
    billingo_public_key: Nullable<string>
    id: number
    suspended_at: Nullable<string>
  }
>

export type PayeeIntegrationProvider = CompanyIntegration<
  CompanyIntegrationProviders.payee,
  {
    id: number
    key: Nullable<string>
  }
>

export type SzamlazzIntegrationProvider = CompanyIntegration<
  CompanyIntegrationProviders.szamlazz,
  {
    id: number
  }
>

export type VasfalaIntegrationProvider = CompanyIntegration<
  CompanyIntegrationProviders.vasfala,
  {
    id: number
    nosz_username: Nullable<string>
    nosz_xml_signature_key: Nullable<string>
    nosz_xml_exchange_key: Nullable<string>
  }
>

export type IntegrationProvider =
  | AcountoIntegrationProvider
  | BillingoIntegrationProvider
  | PayeeIntegrationProvider
  | SzamlazzIntegrationProvider
  | VasfalaIntegrationProvider

export interface IntegrationsBlockState {
  companyIntegrations: IntegrationProvider[]
  error: Nullable<string>
  fetched: boolean
  loading: boolean
}

export type CompanyIntegrationListResponse = { integrations: IntegrationProvider[] }
